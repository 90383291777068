import { Container } from 'src/components/Container/Container'

import type { HtmlModule } from 'src/types/Module'

export const Html = ({ content, fullWidth = false }: HtmlModule) => (
  <Container
    wide={fullWidth}
    className="htmlblock"
    dangerouslySetInnerHTML={{ __html: content }}
    suppressHydrationWarning
  />
)
